
export default {
  name: 'Content',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    title() {
      return this.item?.title?.replace(/<\/?[^>]+(>|$)/g, '') || ''
    },
    time() {
      const valid = new Date(Number(this.item.date)).getTime() > 0
      return valid
        ? this.format(this.fromUnixTime(this.item.date), 'yyyy-MM-dd')
        : ''
    },
  },
}
