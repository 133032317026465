
export default {
  name: 'GalleryCarousel',
  props: {
    mediaCollection: {
      type: Array,
      default: () => [],
    },
    activeItem: {
      type: Object,
      default: () => {},
    },
    hideSideImages: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isDragging: false,
      showCarousel: false,
      initialMousePosition: 0,
      activeSlideIndex: 1,
      isMouseDown: false,
      slideWidth: 0,
      additionalDrag: 0,
      isVisible: false,
    }
  },
  computed: {
    translateDirection() {
      let screenWidth = 0
      if (process.client) screenWidth = window.innerWidth
      const center = screenWidth / 2 - this.slideWidth / 2

      if (process.client && window.innerWidth > 900) {
        return parseInt(`${center + this.slideWidth * -this.activeSlideIndex}`)
      } else {
        return parseInt(`${this.slideWidth * -this.activeSlideIndex}`)
      }
    },
    itemsForSlider() {
      const cloneLast =
        this.mediaCollection[this.mediaCollection.length - 1] || {}
      const cloneFirst = this.mediaCollection[0] || {}
      cloneLast.clone = true
      cloneFirst.clone = true
      return [cloneLast, ...this.mediaCollection, cloneFirst]
    },
    hasContent() {
      return (
        this.mediaCollection?.[this.activeSlideIndex - 1]?.title?.length > 0
      )
    },
  },
  watch: {
    activeItem: {
      handler(val) {
        if (val) {
          this.setActiveIndex(val)
          this.setSliderPosition()
        }
      },
      immediate: true,
    },
    showCarousel: {
      handler(val) {
        if (val === false) {
          this.$emit('clearActiveItem')
          this.isVisible = false
        }
        if (val === true) {
          document.addEventListener('keyup', this.arrowNavigation)
          requestAnimationFrame(() => {
            this.isVisible = true
          })
        }
      },
      immediate: true,
    },
  },
  unmounted() {
    document.removeEventListener('keyup', this.arrowNavigation)
    this.isVisible = false
  },
  methods: {
    onSlideMounted(index) {
      if (this.activeSlideIndex === index) {
        this.setSliderWidth(index)
      }
    },
    touchStart(e) {
      this.initialMousePosition = this.getPositionX(e)
      this.isDragging = true
    },
    touchEnd() {
      this.isDragging = false
      this.additionalDrag = 0
      this.setSliderPosition()
    },
    touchMove(e) {
      const xPosition = this.getPositionX(e)
      if (xPosition > 0 && this.isDragging) {
        if (this.additionalDrag > 50) {
          this.updateActiveSlideIndex('prev')
          this.isDragging = false
        } else if (this.additionalDrag < -50) {
          this.updateActiveSlideIndex('next')
          this.isDragging = false
        } else {
          this.additionalDrag = -(this.initialMousePosition - xPosition)
          this.setSliderPosition()
        }
      }
    },
    setSliderWidth(index = 0) {
      this.slideWidth = this.$refs.slider.children[index].offsetWidth
    },
    setActiveIndex(item) {
      this.showCarousel = true
      this.activeSlideIndex = this.mediaCollection.indexOf(item) + 1
    },
    setSliderPosition() {
      this.$nextTick(() => {
        if (!this.$refs.slider?.style) return
        this.$refs.slider.style.transform = `translateX(${
          this.translateDirection + this.additionalDrag
        }px)`
        if (this.isDragging) {
          requestAnimationFrame(this.setSliderPosition)
        }
      })
    },
    updateActiveSlideIndex(val) {
      const arrayLength = this.mediaCollection.length
      if (val === 'next' && this.activeSlideIndex < arrayLength) {
        this.activeSlideIndex++
      } else if (val === 'prev' && this.activeSlideIndex > 1) {
        this.activeSlideIndex--
      }
      this.setSliderPosition()
    },
    arrowNavigation(event) {
      if (event.key === 'ArrowLeft') this.updateActiveSlideIndex('prev')
      if (event.key === 'ArrowRight') this.updateActiveSlideIndex('next')
      if (event.key === 'Escape') this.showCarousel = false
    },
    getPositionX(event) {
      return event.type.includes('mouse')
        ? event.pageX
        : event.touches[0].clientX
    },
  },
}
