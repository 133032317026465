
import VClamp from 'vue-clamp'
export default {
  name: 'ThumbPhotoRacing',
  components: {
    VClamp,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    iteration: {
      type: Number,
      default: 0,
    },
    firstBig: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      return this.item?.title?.replace(/<\/?[^>]+(>|$)/g, '') || ''
    },
    image() {
      const img =
        this.item.type === 'image'
          ? this.item.image
          : `https://img.youtube.com/vi_webp/${this.item.videoID}/maxresdefault.webp`

      return img
    },
  },
}
