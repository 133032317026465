
export default {
  name: 'GalleryWrapper',
  props: {
    galleryData: {
      type: Array,
      default: () => [],
    },
    archiveLink: {
      type: String,
      default: '',
    },
    darken: {
      type: Boolean,
      default: false,
    },
    sectionTitle: {
      type: String,
      default: '',
    },
    linkTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeItem: null,
    }
  },
  computed: {
    thumbs() {
      return this.slides.slice(0, 5)
    },
    slides() {
      return [...this.galleryData].reverse()
    },
  },
}
