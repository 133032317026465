
import { fromUnixTime, format } from 'date-fns'
import { getEmbeddedUrl } from '@/utils'
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iframeUrl() {
      return getEmbeddedUrl(this.item.videoLink, false, true)
    },
  },
  watch: {
    isActive(newValue, oldValue) {
      if (
        oldValue === true &&
        newValue === false &&
        this.item.type === 'video'
      ) {
        this.$nextTick(() => {
          try {
            this.$refs.iframe.contentWindow.postMessage(
              '{"event":"command","func":"stopVideo","args":""}',
              '*',
            )
          } catch (ex) {
            console.error('slide.vue isActive method error', ex)
          }
        })
      }
    },
  },
  mounted() {
    this.$emit('slideMounted')
  },
  methods: {
    fromUnixTime,
    format,
  },
}
